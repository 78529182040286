/* Change the white to any color ;) */
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

	body, html{
		margin:0px;
		padding:0px;
		width:100%;
		min-height:100vh;
		background-image:url(../images/mainbg.jpg?3);
		background-color:#f5d09e;
		background-size:100% auto;
		background-position:top center;
		background-repeat:no-repeat;
	}


.albums
{
	position:absolute;
	bottom:20px;
	z-index:1;
	left:0px;
	height:150px;
	width:100%;
	overflow:hidden;
	border-bottom:2px solid rgba(0,0,0,.3);
	background:rgba(0,0,0,.6);

}

.albums_holder
{
	width:auto;
	white-space:nowrap;
	position:relative;
}

.albums img{
	width:150px;
	height:150px;
	box-shadow:0px 0px 3px rgba(0,0,0,.4);
	position:relative;

}
		#header
		{
			width:100%;
			z-index:2;
			position:fixed;
			top:26px;
			left:0px;
			height:80px;
			
			background-color:rgba(255,255,255,.9);
			
		}


		#header .logo
		{
			margin:0px;
			margin-left:5px;
			margin-top:25px !important;
			float:left;
			height:80px;
			max-width:40%;
			margin-left:15px;
			position:relative;
			top:-20px;

		}		

	.form-holder
	{
		background-color:#fff;
		border-radius:3px;
		border:1px solid #ececec;
		padding:20px;
		margin-top:20px;
		z-index:9;
		position:relative;
	}

	#register_form_holder_registered
	{
		display:none;
	}

	.input-group-lg
	{
		margin-bottom:10px;
	}

	.error_message
	{
		display:none;
	}
	.form_big  input.error, .form_big  textarea.error, .form_big  select.error
	{
		//border:2px solid red !important; 
		background-color:#f2dede !important;
		
	}

	.whitegrad
	{
		position:fixed;
		top:0px;
		left:0px;
		height:50vh;
		background:linear-gradient(to bottom,#fff,rgba(255,255,255,0));
		z-index:1;
		width:100%;
		display:none;
	}

	.promo
	{
		padding-top:20px;
	}

	.promo h1{
		font-size:36px;
		line-height:38px;
		text-align:center;
		color:#1f354a;
		text-shadow:0px 0px 10px #fff, 0px 0px 1px #fff;
	}

	.promo h2{
		font-size:18px;
		line-height:26px;
		text-align:left;
		
		margin-left:10px;
		padding:10px;
		color:#fff;
		background:rgba(0,0,0,.5);
		text-shadow:0px 0px 10px rgba(0,0,0,.2);
	}

	.promo h2 span{
		border-left:3px solid #fff;
		
		padding-left:10px;
		display:block;
	}

	.promo h2 span .bullets li
	{
		font-weight:100;
		list-style:square;
	}

	.btn-start, .btn-login
	{
		background-color:#ff8a00;
		border:1px solid #f78600;
		padding-left:50px;
		background-image:url(../images/button_play.png);
		background-repeat:no-repeat;
		background-size: 20px 20px;
		background-position:12px 12px;
	}

	.btn-start:hover, .btn-login:hover
	{
		background-color:#f78600;
		border:1px solid #f78600;
	}

	.btn-login
	{
		padding-left:16px;
		background-image:none;
	}

	.promo .logos img{
		display:inline-block;
		vertical-align:top;
		height:60px;
		margin:5px;
	}

	#footer
	{
		width:100%;
		height:150px;
		background-color:#111;
		padding-top:15px;
		text-align:center;
	}

	#footer .logo
	{
		text-align:center;
		color:#ccc;
		font-size:12px;
	}

	#footer .logo img{
		width:120px;
	}


/* PLAYER */

		#vidbg
		{
			width:110vw;
			height:auto;
			left:-5vh;
			//top:-vh;
			bottom:0px;
			position:fixed;
			z-index:1 !important;
		}
		
		#player_controls
		{
			position:fixed;
			bottom:0px;
			left:0px;
			width:100%;
			height:110px;
			background-color:#fff;
			
			z-index:999;
			padding:5px;
			box-sizing:border-box;
		}

		#nowplaying, #options
		{
			position:absolute;
			left:5px;
			top:5px;
			height:100px;
			width:500px;
			max-width:40%;
		}

		#options
		{
			left:auto;
			right:5px;
		}

		#nowplaying img{
			height:90px;
			margin:5px;
			margin-right:10px;
			float:left;
		}

		#nowplaying .vcenter
		{
			width:200px;
			height:90px;
			margin:5px;
			display:flex;
			//justify-items:center;
			vertical-align:middle;
			align-items:center;
		}

		#nowplaying .artist
		{
			font-size:16px;
			line-height:18px;
			margin:0px;
			padding:0px;
			display:inline-block;
			width:auto;
			color:#666;
			font-weight:200;
			letter-spacing:1px;

		}

		#nowplaying .song
		{
			 page-break-after: always; 
  			break-after: always;
			font-size:20px;
			line-height:22px;
			margin:0px;
			padding:0px;
			display:inline-block;
			width:auto;
			font-weight:400;
			color:#333;
			
			letter-spacing:.5px;
		}

		#options .button
		{
			float:right;
			
			background-size: 40px auto;
			background-position:22px 15px;
			background-repeat:no-repeat;
			display:inline-block;
			width:90px;
			margin:5px;
			height:90px;
			text-align:center;
			font-size:12px;
			line-height:20px;
			font-weight:200;
			color:#000;
			text-decoration:none;
			text-align:center;
			box-sizing:border-box;
			padding-top:60px;
			cursor:pointer;
			transition:all 0.3s ease-in-out;
			-webkit-transition:all 0.3s ease-in-out;
		}

		#options .button:hover
		{
			background-color:#8fcb4c;
			
			color:#fff;
		}

		#options .button_buynow
		{
			background-image:url(../images/icon_buy.png);
		}

		#options .button_buynow:hover
		{
			background-image:url(../images/icon_buy_white.png);
		}

		#options .button_share
		{
			background-image:url(../images/icon_share.png);
		}

		#options .button_share:hover
		{
			background-image:url(../images/icon_share_white.png);
		}

		#options .button_popout
		{
			background-position:28px 14px;
			background-image:url(../images/icon_popout.png);
		}

		#options .button_popout:hover
		{
			background-image:url(../images/icon_popout_white.png);
		}		

		#options .button_stations
		{
			background-image:url(../images/button_stations.png);
		}

		#options .button_stations:hover
		{
			background-image:url(../images/button_stations_white.png);
		}

		#options .button_favorite
		{
			background-position:25px 15px;
			background-image:url(../images/icon_favorite.png);
		}

		#options .button_favorite:hover
		{
			background-image:url(../images/icon_favorite_white.png);
		}		

		#options .button_favorite.focus
		{
			background-image:url(../images/icon_favorite_focus.png);
		}


		#header
		{
			width:100%;
			z-index:9;
			position:fixed;
			top:26px;
			left:0px;
			height:80px;
			
			background-color:rgba(255,255,255,.8);
			
		}

		#userinfo
		{
			z-index:2;
			left:0px;
			width:100%;
			background:linear-gradient(to left,#000,rgba(0,0,0,0),rgba(0,0,0,0));
			color:#fff;
			font-size:13px;
			text-align:right;
			position:fixed;
			top:0px;
			height:26px;
			line-height:26px;
			box-sizing:border-box;
			padding-right:0px;
		}

		#userinfo a
		{
			display:inline-block;
			height:26px;
			line-height:26px;
			font-size:13px;
			border-left:1px solid #ccc;
			border-right:1px solid #ccc;
			text-transform:uppercase;
			color:#ccc;
			text-decoration:none;
			padding-left:8px;
			padding-right:8px;
			transition:all 0.3s ease-in-out;
			-webkit-transition:all 0.3s ease-in-out;
			-moz-transition:all 0.3s ease-in-out;
			-ms-transition:all 0.3s ease-in-out;
			-o-transition:all 0.3s ease-in-out;
			
			
		}

		#userinfo a:hover
		{
			background-color:#ff8a00;
			color:#fff;
		}

		#header .logo
		{
			margin:0px;
			margin-left:5px;
			margin-top:5px;
			float:left;
			height:80px;
			max-width:40%;
		}

		#header .station_logo
		{
			margin:5px;
			margin-left:10px;
			max-height:80px;
			float:left;
			max-width:40%;
		}

		#header .slash
		{
			display:inline-block;
			vertical-align:top;
			line-height:80px;
			font-size:70px;
			color:#fff;
			font-weight:100;
			float:left;
			margin-left:10px;
			position:relative;
			top:-5px;
		}
		.button_sponsors_mobile
		{
			display:none;
		}

		.button_sponsors
		{

			width:50%;
			max-width:320px;
			height:100%;
			float:right;
			display:block;
			text-align:center;
			color:#fff;
			font-size:20px;
			line-height:22px;
			font-weight:200;
			padding-top:16px;
			background-color:#8fcb4c;
			text-decoration:none;
			box-sizing:border-box;
			padding-right:45px;
			background-image:url(../images/icon_plus.png);
			background-repeat:no-repeat;
			background-position: right 25px;
		}

		.button_sponsors.expanded
		{
			background-image:url(../images/icon_minus.png);
		}

		.button_sponsors b{
			display:block;
			font-size:25px;
		}

		#sponsors
		{
			width:320px;
			box-sizing:border-box;
			padding:10px;
			position:fixed;
			top:106px;
			right:0px;
			background-color:#8fcb4c;
			height:100vh;
			display:none;
			z-index:99 !important;
			overflow-x:hidden;
			overflow-y:visible;

		}

		.ad300x250 
		{
			border:3px solid #fff;
			margin-bottom:8px;
			box-sizing:border-box;
			line-height:0px;
		}

		.ad300x250 img{
			width:100%;
		}

		#header .weather
		{
			float:right;
			width:160px;
			text-align:center;
			color:#000;
			font-family:Tahoma,sans-serif;
			font-size:14px;
			font-weight:400;
			margin-right:30px;
			color:#184878;
			margin-top:10px;
			max-width:40%;
		}

		#header .weather .weather-icon
		{
			font-size:45px;
			line-height:60px;
			text-align:center;
			display:block;
			width:100%;
			font-weight:800;
		}

		#header .weather .weather-icon img
		{
			height:60px;
			float:left;
			margin-right:10px;
		}


		.button_play
		{
			margin:5px;
			position:relative;

			background-color:#8fcb4c;
			border-radius:45px;
			
			background-image:url(../images/button_play.png);
			background-repeat:no-repeat;
			background-size:50px 50px;
			border:0px solid #ccc;
			width:90px;
			height:90px;
			background-position:25px center;			
			cursor:pointer;
			display:inline-block;
			transition:all 0.3s ease-in-out;
			-webkit-transition:all 0.3s ease-in-out;
			-ms-transition:all 0.3s ease-in-out;
			-moz-transition:all 0.3s ease-in-out;
			-o-transition:all 0.3s ease-in-out;
		}

		.button_play .circle
		{
			border-radius:150px;
			width:150px;
			height:150px;
			border:10px solid #8fcb4c;
			box-sizing:border-box;
			transform:scale(0);
			transform-origin:center center;
			left:-30px;
			top:-30px;
			position:absolute;
			transition:all 1.0s ease-in-out;
			-webkit-transition:all 1.0s ease-in-out;
			-ms-transition:all 1.0s ease-in-out;
			-moz-transition:all 1.0s ease-in-out;
			-o-transition:all 1.0s ease-in-out;			
		}

		.button_play:hover .circle
		{
			left:-40px;
			top:-40px;
		}

		.button_play.animate .circle{
			transform:scale(1);
			opacity:0;
		}

		.button_play:hover
		{
			width:70px;
			height:70px;
			background-position:15px center;
			transition:all 0.3s ease-in-out;
			-webkit-transition:all 0.3s ease-in-out;
			border:10px solid #ececec;

		}

		.button_pause
		{
			background-image:url(../images/button_pause.png);
			background-position:center center;
		}

		.button_pause:hover
		{
			background-position:center center;
		}

		#blackout
		{
			position:fixed;
			top:0px;
			left:0px;
			width:100%;
			height:100vh;
			background:rgba(0,0,0,.8);
			display:none;
			z-index:9999;
		}

		.popup
		{
			display:none;
			width:500px;
			height:110px;
			padding:10px;
			max-width:100%;
			background-color:#fff;
			border:1px solid rgba(255,255,255,.5);
			box-shadow:0px 0px 4px rgba(0,0,0,.2);
			z-index:99999;
			box-sizing:border-box;
			position:fixed;
			padding-top:10px;
			left:0px;
		}

		.popup h1
		{
			color:#333;
			text-align:center;
			font-size:18px;
			line-height:24px;
			box-sizing:border-box;
			padding-left:20px;
			padding-right:20px;
			font-weight:100;
			padding-bottom:0px;
			margin-bottom:0px;
		}

		#popup_stations
		{
			height:430px;
			overflow-y:scroll;
			overflow-x:hidden;
		}

		#popup_stations ul{
			list-style:none;
			margin:0px;
			padding:0px;
			width:100%;

		}

		#popup_stations ul li{
			list-style:none;
			margin:0px;
			padding:0px;
			width:100%;
		}

		#popup_stations ul li a{
			display:block;
			position:relative;
			height:70px;
			padding-top:10px;
			padding-bottom:10px;
			font-size:12px;
			text-align:center;
			box-sizing:border-box;
			padding-left:100px;
			color:#333;
			text-decoration:none;
			font-weight:100;
			line-height:18px;
			border-bottom:1px solid #ccc;
			transition:all 0.3s ease-in-out;
			-webkit-transition:all 0.3s ease-in-out;
			-ms-transition:all 0.3s ease-in-out;
			-o-transition:all 0.3s ease-in-out;
			-moz-transition:all 0.3s ease-in-out;
		}

		#popup_stations ul li a b{
			font-size:24px;
			display:inline-block;
			padding-top:5px;

		}

		#popup_stations ul li a img{
			width:100px;
			position:absolute;
			top:5px;
			left:5px;
		}

		#popup_stations ul li a:hover
		{	
			color:#fff;
		}

		.popup .close
		{
			position:absolute;
			right:10px;
			cursor:pointer;
			top:10px;
			font-size:25px;
			height:25px;
			width:25px;
			display:block;
			color:#666;
			transition:all 0.3s ease-in-out;
			-webkit-transition:all 0.3s ease-in-out;
		}

		.popup .close:hover
		{
			color:#333;
		}

		#popup_favorites
		{
			position:absolute;
			top:120px;
			left:10px;
			height:auto;
			z-index:9999 !important;
			overflow-x:hidden;
			overflow-y:scroll;
		}

		#popup_favorites table{
			width:100%;
			border:0px;
			border-collapse:collapse;
		}

		#popup_favorites table td{
			padding-top:8px;
			padding-bottom:8px;
			text-align:left;
			border-collapse:collapse;
			border-bottom:1px solid #999;
			font-size:14px;
			line-height:18px;
		}

		#popup_favorites table img.album{
			max-height:80px;

		}

		#popup_favorites table img.station{
			max-height:40px;
		}

		#popup_favorites table img.buylink
		{
			max-height:40px;
		}

		#popup_favorites table td .delete
		{
			color:red;
			text-decoration:none;
			font-weight:300;
			font-size:12px;
			line-height:12px;
			text-align:center;
		}

		#popup_favorites table td small{
			display:block;
			font-size:12px;
			color:#333;
		}
		

		@media screen and (max-width:600px)
		{

			#popup_stations
			{
				height:350px;
			}

			#popup_stations ul li a{
				display:block;
				position:relative;
				height:50px;
				padding-top:5px;
				padding-bottom:10px;
				font-size:12px;
				text-align:center;
				box-sizing:border-box;
				padding-left:100px;
				color:#333;
				text-decoration:none;
				font-weight:100;
				line-height:18px;
				border-bottom:1px solid #ccc;
				transition:all 0.3s ease-in-out;
				-webkit-transition:all 0.3s ease-in-out;
				-ms-transition:all 0.3s ease-in-out;
				-o-transition:all 0.3s ease-in-out;
				-moz-transition:all 0.3s ease-in-out;
			}

			#popup_stations ul li a b{
				font-size:16px;
				display:inline-block;
				padding-top:0px;

			}

			#popup_stations ul li a img{
				width:80px;
				position:absolute;
				top:5px;
				left:5px;
			}			
			#options .button_popout
			{
				display:none;
			}

			#header
			{
				//background:linear-gradient(to bottom, #fff,rgba(255,255,255,0));
			}

			#nowplaying
			{
				position:fixed;
				bottom:80px;
				top:auto;
				max-width:100%;
				left:0px;
				width:100%;
				background-color:rgba(255,255,255,.8);
				z-index:999 !important;
				box-shadow:0px -2px 2px rgba(0,0,0,.4);
			}

			#nowplaying table{
				width:200px;
			}

			#options .button_buynow
			{
				position:fixed;
				float:none;
				left:75px;
				bottom:5px;
			}


			.button_sponsors_mobile
			{
				display:block;
				background-color:#8fcb4c;
				width:100px;
				height:30px;
				line-height:30px;
				color:#fff;
				text-align:center;
				font-weight:bold;
				transform:rotate(-90deg);
				transform-origin:top left;
				position:absolute;
				left:-30px;
				top:150px;
				z-index:9999;
				padding-left:40px;
				padding-right:10px;
				background-image:url(../images/icon_minus.png);
				background-size:auto 24px;
				background-position: 3px 3px;
				background-repeat:no-repeat;
			}

			#sponsors.collapsed .button_sponsors_mobile
			{
				background-image:url(../images/icon_plus.png);
			}

			#sponsors.collapsed 
			{
				right:-220px;
			}

			.button_sponsors
			{
				display:none;


			}

			.button_sponsors b
			{
				font-size:20px;
				display:inline;
				margin-right:5px;
			}

			#sponsors
			{
				top:106px;
				box-shadow:-3px 2px 3px rgba(0,0,0,.4);
				z-index:99 !important;
				overflow-x:visible;
				transition:all 0.3s ease-in-out;
				-webkit-transition:all 0.3s ease-in-out;
				-moz-transition:all 0.3s ease-in-out;
				-o-transition:all 0.3s ease-in-out;
				-ms-transition:all 0.3s ease-in-out;


			}

			#sponsors
			{
				width:220px;
			}

			#sponsors .content img{
				max-width:100%;
			}

			#sponsors .content 
			{
				height:100%;
				overflow-x:hidden;
				overflow-y:scroll;

			}

			#player_controls
			{
				position:fixed;
				bottom:0px;
				left:0px;
				width:100%;
				height:80px;
				background-color:#fff;
				
				z-index:9999 !important;
				padding:5px;
				box-sizing:border-box;
			}			

			#options .button
			{
				float:right;
				
				background-size: 30px auto;
				background-position:18px 10px;
				background-repeat:no-repeat;
				display:inline-block;
				width:70px;
				margin-left:5px;
				margin:0px;
				height:70px;
				text-align:center;
				font-size:12px;
				line-height:20px;
				font-weight:200;
				color:#000;
				text-decoration:none;
				text-align:center;
				box-sizing:border-box;
				padding-top:40px;
				cursor:pointer;
				transition:all 0.3s ease-in-out;
				-webkit-transition:all 0.3s ease-in-out;
			}

			.button_play
			{
				position:relative;
				top:-30px;
				z-index:99999 !important;
			}
			#userinfo
			{
				top:0px;
				background:rgba(0,0,0,.8);
			}

			#userinfo a
			{
				float:right;
			}

			#options .button_favorite
			{
				position:fixed;
				float:none;
				left:5px;
				bottom:5px;
				background-position:21px 10px;
			}

			#options .button_share
			{
				position:fixed;
				float:none;
				right:5px;
				bottom:5px;
			}

			#options .button_stations
			{
				position:fixed;
				right:75px;
				bottom:5px;
				float:none;
			}

			#popup_favorites
			{
				position:absolute;
				top:105px;
				left:0px;
				width:100%;
				height:auto;
				z-index:9999 !important;
				overflow-x:hidden;
				overflow-y:scroll;
			}			

		}

		@media screen and (max-width:320px)
		{
			#options .button
			{
				float:right;
				
				background-size: 20px auto;
				background-position:12px 7px;
				background-repeat:no-repeat;
				display:inline-block;
				width:50px;
				margin-left:5px;
				margin:0px;
				height:50px;
				text-align:center;
				font-size:12px;
				line-height:20px;
				font-weight:200;
				color:#000;
				text-decoration:none;
				text-align:center;
				box-sizing:border-box;
				padding-top:25px;
				cursor:pointer;
				transition:all 0.3s ease-in-out;
				-webkit-transition:all 0.3s ease-in-out;
			}		

			#options .button_stations
			{
				position:fixed;
				right:55px;
				bottom:13px;
				float:none;
			}			

			#options .button_buynow
			{
				position:fixed;
				left:55px;
				bottom:13px;
				float:none;
				overflow:hidden;
			}			

			#options .button_share, #options .button_favorite
			{
				bottom:13px;
			}
		}


/* INTRO LOGO */



#logo
{
	width:500px;
	height:500px;
	transform:scale(0.7);
	position:relative;
	user-select:none;
}

#logo .play
{
	position:absolute;
	width:100px;
	left:200px;
	top:200px;
	z-index:9;
	transition:all 1.8s ease-in-out;
	user-select:none;
}

#logo.animate .play
{
	transform:rotate(480deg);
}

#logo .islands
{
	position:absolute;
	width:100%;
	height:100%;
	z-index:2;
	top:50px;
	left:0px;
	opacity:0;
	transform-origin:center center;
	transform:scale(0) rotate(-90deg);
	transition:all 1.8s ease-in-out;
	user-select:none;

}

#logo.animate .islands
{
	transform:scale(1) rotate(0deg);
	opacity:.4;
}

#logo .text
{
	position:absolute;
	
	top:230px;
	left:0;
	width:100%;
	z-index:2;
	overflow:hidden;
	height:50px;
	font-size:40px;
	font-family:Tahoma,sans-serif;
	font-weight:600;
	transition:all 1.8s ease-in-out;
	transition-delay:0.5s;
	white-space:nowrap;
	opacity:0;
	user-select:none;
	color:#fff;
}

#logo .text i{
	font-weight:100;
}

#logo.animate .text
{
	opacity:1;
	padding-left:5px;
	color:#000;
}

#logo .circle
{
	position:absolute;
	left:50%;
	top:50%;
	z-index:1;
	border-radius:100%;
	border:8px solid #ff8400;
	width:0px;
	height:0px;
	transition:all 1.6s ease-in-out;
	transition-delay:0.2s;
	box-sizing:border-box;
}

#logo.animate .circle
{
	width:50%;
	height:50%;
	left:25%;
	top:25%;
	opacity:0;
}

#logo_overlay
{
	position:fixed;
	z-index:99999999;
	top:0px;
	left:0px;
	width:100%;
	height:100%;
	background-color:#fcfcfc;
	background-image:url(../images/logo_animate_bg.jpg);
	background-repeat:repeat;
}

.mobile-menu-toggle
{
	display:none;
}

.mobile-menu
{
	display:inline-block;
}

@media screen and (max-width:600px)
{
	.mobile-menu-toggle
	{
		display:inline-block;
		width:auto;
		padding-left:10px;
		padding-right:10px;
		color:#fff;
		border:1px solid #fff;
		height:30px;
		font-size:11px;
		text-align:center;

		
	}

	.mobile-menu
	{
		display:none;
	}
}
